var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.domain)?_c('StripeCheckout',{attrs:{"title":"Gift Cards","customTextAfterSubmit":"Cards will be printed and mailed within 3 business days.","customTextAlongsidePaymentConfirmationButton":"This embossing will appear on all cards in your order. The gift card amount will be embossed on the card below the expiration date.","customFields":[
    {
      key: 'EMBOSSED_MESSAGE',
      label: 'Embossed Text (Allowable: A-Z, 0-9, 21 char max)',
      optional: false,
      maximumLength: 21
    }
  ],"phoneNumberCollection":true,"successUrl":_vm.domain + '/#/admin/giftCards/success',"cancelUrl":_vm.domain + '/#/admin/giftCards/cancelled'}}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }