<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <ApiError :errors="errors"></ApiError>
      <div class="text-center" v-if="loading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <v-simple-table v-else :key="refresh">
        <template v-slot:default>
          <thead>
            <tr>
              <th :colspan="hasImages ? 2 : 1" class="text-left">
                Products
              </th>
              <th class="text-right">
                Unit Price
              </th>
              <th class="text-center">
                Qty to Purchase
              </th>
              <th class="text-right">
                Total to Purchase
              </th>
            </tr>
          </thead>
          <tbody>
            <template>
              <tr v-for="(item, index) in items" :key="item.id">
                <td v-if="hasImages">
                  <v-img max-width="130" :src="item.images[0]" class="ma-2"></v-img>
                </td>
                <td>
                  <v-list-item three-line v-if="item.id">
                    <v-list-item-content>
                      <v-list-item-title class="text-wrap">
                        <v-row>
                          <v-col align="left" cols="6" v-html="item.name"> </v-col>
                        </v-row>
                      </v-list-item-title>
                      <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </td>
                <td class="text-right text-no-wrap">
                  {{ item.price | toNumber(2, selectedProgram) }}
                </td>
                <td class="text-center text-no-wrap" style="width: 150px;">
                  <v-text-field
                    :ref="'item-field-' + index"
                    dense
                    outlined
                    v-model="items[index].qty"
                    @change="onQtyChange(index)"
                    min="0"
                    type="number"
                    class="stripe-checkout-qty"
                    :max="maxQty"
                    hide-details
                  ></v-text-field>
                </td>
                <td class="text-right text-no-wrap">
                  <b>
                    {{ (item.qty * item.price) | toNumber(2, selectedProgram) }}
                  </b>
                </td>
              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr v-for="shippingRate in shippingRates" :key="shippingRate.id">
              <td :colspan="hasImages ? 4 : 3" class="text-right">
                {{ shippingRate.displayName }}
              </td>
              <td class="text-right text-no-wrap">
                {{ shippingRate.amount | toNumber(2, selectedProgram) }}
              </td>
            </tr>
            <tr>
              <th :colspan="hasImages ? 4 : 3" class="text-right">
                Subtotal
              </th>
              <th class="text-right text-no-wrap">
                {{ totalAmount | toNumber(2, selectedProgram) }}
              </th>
            </tr>
            <tr>
              <td :colspan="hasImages ? 5 : 4" class="text-right">
                <v-btn
                  class="mt-5 mb-2"
                  :loading="loading"
                  @click="onCheckout"
                  :disabled="totalQuantity() <= 0"
                  color="primary"
                  >Checkout <v-icon small right>mdi-arrow-right</v-icon></v-btn
                >
              </td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<style>
.stripe-checkout-qty {
  width: 150px;
}

.stripe-checkout-qty input {
  text-align: center;
}
</style>

<script>
import ApiError from "../../display/ApiError.vue";
import { mapGetters } from "vuex";

export default {
  name: "StripeCheckout",
  metaInfo: {
    title: "Checkout"
  },
  components: {
    ApiError
  },
  data: function() {
    return {
      valid: false,
      loading: false,
      errors: {},
      headers: [],
      items: [],
      shippingRates: [],
      hasImages: false,
      maxQty: 250,
      totalAmount: 0,
      refresh: 1
    };
  },
  props: {
    title: { type: String, default: "Checkout" },
    customTextAfterSubmit: String,
    customTextAlongsidePaymentConfirmationButton: String,
    customFields: { type: Array, default: null }, // [{ key: "", label: "", optional: false }]
    phoneNumberCollection: { type: Boolean, default: false },
    successUrl: { type: String, default: window.location.origin + "/#/checkout/success" },
    cancelUrl: { type: String, default: window.location.origin + "/#/checkout/cancelled" },
    openNewWindow: { type: Boolean, default: false }
  },
  methods: {
    onCheckout() {
      this.loading = true;
      this.errors = {};

      let lineItems = this.items
        .filter(item => item.qty > 0)
        .map(item => {
          return {
            id: item.priceId,
            quantity: item.qty
          };
        });

      let postForm = {
        customFields: this.customFields,
        customTextAfterSubmit: this.customTextAfterSubmit,
        customTextAlongsidePaymentConfirmationButton: this.customTextAlongsidePaymentConfirmationButton,
        phoneNumberCollection: this.phoneNumberCollection,
        successUrl: this.successUrl,
        cancelUrl: this.cancelUrl,
        lineItems: lineItems
      };

      return this.$api
        .post("/api/stripe/checkout/session", postForm)
        .then(({ data }) => {
          let url = data;
          if (this.openNewWindow) {
            window.open(url);
          } else {
            window.location.href = url;
          }
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    refreshAmounts() {
      this.calculateTotalAmount();
      this.refresh++;
      this.$forceUpdate();
    },
    onQtyChange(index) {
      let qty = this.items[index].qty;
      if (qty < 0) {
        qty = 0;
        this.items[index].qty = qty;
        this.refreshAmounts();
      } else if (qty > this.maxQty) {
        qty = this.maxQty;
        this.items[index].qty = qty;
        this.refreshAmounts();
      } else {
        this.refreshAmounts();
      }
    },
    decreaseQuantity(index) {
      let qty = this.items[index].qty;
      if (qty > 0) {
        qty--;
        this.items[index].qty = qty;
        this.refreshAmounts();
      }
    },
    increaseQuantity(index) {
      let qty = this.items[index].qty;
      if (qty < this.maxQty) {
        qty++;
        this.items[index].qty = qty;
        this.refreshAmounts();
      }
    },
    calculateTotalAmount() {
      if (!this.items || this.items.length == 0) {
        this.totalAmount = 0;
      }
      let productsAmount = this.items.reduce((a, b) => a + b.price * b.qty, 0);
      let shippingRatesAmount = this.shippingRates.reduce((a, b) => a + b.amount, 0);

      this.totalAmount = productsAmount + shippingRatesAmount;
    },
    fetchStripeProducts() {
      return this.$api.get("/api/stripe/products").then(({ data }) => {
        this.items = data;
        for (let i = 0; i < this.items.length; i++) {
          this.items[i].qty = 0;
        }
        this.hasImages = this.items.some(item => item.images && item.images.length > 0);
      });
    },
    fetchStripeShippingRates() {
      return this.$api.get("/api/stripe/shippingRates").then(({ data }) => {
        this.shippingRates = data;
      });
    },
    totalQuantity() {
      let total = 0;
      for (let i = 0; i < this.items.length; i++) {
        total += this.items[i].qty;
      }
      return total;
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient", "selectedProgram"])
  },

  mounted() {
    this.loading = true;
    Promise.all([this.fetchStripeProducts(), this.fetchStripeShippingRates()])
      .then(() => {
        this.refreshAmounts();
      })
      .finally(() => {
        this.loading = false;
      });
  }
};
</script>
