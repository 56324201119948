<template>
  <StripeCheckout
    v-if="domain"
    title="Gift Cards"
    customTextAfterSubmit="Cards will be printed and mailed within 3 business days."
    customTextAlongsidePaymentConfirmationButton="This embossing will appear on all cards in your order. The gift card amount will be embossed on the card below the expiration date."
    :customFields="[
      {
        key: 'EMBOSSED_MESSAGE',
        label: 'Embossed Text (Allowable: A-Z, 0-9, 21 char max)',
        optional: false,
        maximumLength: 21
      }
    ]"
    :phoneNumberCollection="true"
    :successUrl="domain + '/#/admin/giftCards/success'"
    :cancelUrl="domain + '/#/admin/giftCards/cancelled'"
  />
</template>

<script>
import StripeCheckout from "../../gapp-components/components/views/checkout/StripeCheckout.vue";
export default {
  components: { StripeCheckout },
  name: "GiftCards",
  metaInfo: {
    title: "Gift Cards"
  },
  data: () => ({
    domain: null
  }),
  mounted() {
    this.domain = window.location.origin;
  }
};
</script>
